import { CssBaseline } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import Helmet from 'react-helmet';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#e8bb59',
    },
    secondary: {
      main: '#30903f',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

if (process.env.NODE_ENV === 'development') {
  console.info('Theme:', theme);
}

export function ThemeProvider({ children }) {
  return (
    <>
      <CssBaseline />
      <Helmet>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500" />
      </Helmet>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </>
  );
}

import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const Checkout = lazy(() => import('./scenes/Checkout'));
const Purchase = lazy(() => import('./scenes/Purchase'));
const Agb = lazy(() => import('./scenes/Agb'));
const Faq = lazy(() => import('./scenes/Faq'));
const Impressum = lazy(() => import('./scenes/Impressum'));
const Contact = lazy(() => import('./scenes/Contact'));
const HowDoesItWorks = lazy(() => import('./scenes/HowDoesItWorks'));
const Debug = lazy(() => import('./scenes/Debug'));
const NotFound = lazy(() => import('./scenes/NotFound'));

export default function Routes() {
  return (
    <Switch>
      <Route path="/purchase" component={Purchase} />
      <Route path="/checkout" component={Checkout} />
      <Route path="/agb" component={Agb} />
      <Route path="/faq" component={Faq} />
      <Route path="/kontakt" component={Contact} />
      <Route path="/so-funktioniert-es" component={HowDoesItWorks} />
      <Route path="/impressum" component={Impressum} />
      <Route path="/_debug" component={Debug} />
      <Route exact path="/" render={() => <Redirect to="/purchase" />} />
      <Route component={NotFound} />
    </Switch>
  );
}

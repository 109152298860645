import { Badge, Fab, withStyles } from '@material-ui/core';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import React from 'react';
import { Link } from 'react-router-dom';
import { useBasket } from '../providers/basket';

function BasketIconWithBadge({ theme, className, classes }) {
  const { basket } = useBasket();
  const size = useMediaQuery(theme.breakpoints.up('md')) ? undefined : 'small';

  if (!basket.items.length) return null;

  return (
    <Link to="/purchase" className={className}>
      <Fab color="primary" aria-label="Basket" size={size}>
        <Badge color="secondary" badgeContent={basket.items.length} classes={{ badge: classes.badge }}>
          <ShoppingCart />
        </Badge>
      </Fab>
    </Link>
  );
}

const styles = {
  badge: {
    // The border color match the background color.
    border: '2px solid #fff',
    background: '#fff',
    color: '#000',
  },
};

export default withStyles(styles, { withTheme: true })(BasketIconWithBadge);

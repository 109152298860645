import { useEffect, useState } from 'react';

export default function Delay({ children, delay }) {
  const [show, setShow] = useState(false);

  useEffect(
    () => {
      const timeout = setTimeout(() => setShow(true), delay);
      return () => clearTimeout(timeout);
    },
    [delay]
  );

  return show && children;
}

import { withStyles } from '@material-ui/core';
import React from 'react';

function Container({ children, classes, className = '', ...props }) {
  return (
    <div className={`${classes.root} ${className}`} {...props}>
      {children}
    </div>
  );
}

const styles = ({ breakpoints, spacing }) => ({
  root: {
    // margin: `0 ${spacing.unit * 3}px`,
    [breakpoints.up('lg')]: {
      width: '100%',
      maxWidth: 1280 - spacing.unit * 6,
      margin: '0 auto',
    },
  },
});

export default withStyles(styles)(Container);

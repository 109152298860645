import { withStyles } from '@material-ui/core';
import React from 'react';

function Alert({ color = 'info', classes, className = '', children, ...props }) {
  return (
    <div className={`${classes.alert} ${classes[color]} ${className}`} {...props}>
      {children}
    </div>
  );
}

const styles = ({ spacing, palette, typography }) => ({
  alert: {
    ...typography.body1,
    padding: spacing.unit * 2,
    '& strong': {
      fontWeight: 500,
      letterSpacing: 0.2,
    },
  },
  info: {
    backgroundColor: palette.primary.light,
    color: palette.primary.contrastText,
  },
  success: {
    backgroundColor: palette.secondary.light,
    color: palette.common.white,
  },
  danger: {
    backgroundColor: palette.error.light,
    color: palette.error.contrastText,
  },
});

export default withStyles(styles)(Alert);

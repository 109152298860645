import { CircularProgress, withStyles } from '@material-ui/core';
import React from 'react';
import Alert from './Alert';

function Loading({ message = 'lädt…', classes, ...props }) {
  return (
    <Alert color="info" {...props}>
      <CircularProgress color="inherit" size={16} className={classes.spinner} />
      {message}
    </Alert>
  );
}

const styles = ({ spacing }) => ({
  spinner: {
    marginRight: spacing.unit,
  },
});

export default withStyles(styles)(Loading);

import { withStyles } from '@material-ui/core';
import React from 'react';
import logoPng from '../assets/Logo.png';

function Hero({ classes, children }) {
  return (
    <div className={classes.root}>
      <img src={logoPng} alt="Logo" className={classes.logo} />
      <div className={classes.content}>{children}</div>
    </div>
  );
}

const styles = ({ breakpoints, palette, spacing, typography }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: spacing.unit * 3,
  },
  logo: {
    maxWidth: `calc(100% - ${spacing.unit * 6}px)`,
    [breakpoints.up('md')]: {
      maxWidth: '100%',
    },
  },
  content: {
    ...typography.body1,
    color: palette.common.white,
    textShadow: '1px 1px 1px rgba(0, 0, 0, .3)',
    marginTop: spacing.unit * 2,
    textAlign: 'center',
    textTransform: 'uppercase',
    [breakpoints.up('sm')]: {
      ...typography.h5,
      color: palette.common.white,
    },
  },
});

export default withStyles(styles)(Hero);

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import SentryWrapper from './components/SentryWrapper';
import './polyfills';

ReactDOM.render(
  <SentryWrapper>
    <App />
  </SentryWrapper>,
  document.getElementById('root')
);

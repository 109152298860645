import { withStyles } from '@material-ui/core';
import React, { Suspense } from 'react';
import Container from './components/Container';
import GlobalLoader from './components/GlobalLoader';
import Hero from './components/Hero';
import Layout from './components/Layout';

function Main({ children, classes }) {
  return (
    <Layout>
      <Hero>Wir kaufen deine gebrauchten Videospiele und Konsolen!</Hero>
      <Container className={classes.content}>
        <Suspense fallback={<GlobalLoader />}>{children}</Suspense>
      </Container>
    </Layout>
  );
}

const styles = ({ spacing }) => ({
  content: {
    padding: `${spacing.unit * 6}px 0`,
  },
});

export default withStyles(styles)(Main);

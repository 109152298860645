import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Main from './Main';
import { BasketProvider } from './providers/basket';
import { ThemeProvider } from './providers/theme';
import Routes from './Routes';

export default function App() {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <BasketProvider>
          <Main>
            <Routes />
          </Main>
        </BasketProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

import { IconButton, Toolbar, withStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState } from 'react';
import Helmet from 'react-helmet';
import Bildmarke from '../assets/Bildmarke.png';
import BodyBackgroundImage from '../assets/BGBP-Website-BG.jpg';
import BasketIconWithBadge from './BasketIconWithBadge';
import DrawerLeft from './DrawerLeft';
import Footer from './Footer';

function Layout({ classes, children }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const openDrawer = () => setDrawerOpen(true);
  const closeDrawer = () => setDrawerOpen(false);

  return (
    <div className={classes.root}>
      <Helmet>
        <body className={classes.body} />
      </Helmet>

      <Toolbar className={classes.toolbar} disableGutters>
        <IconButton onClick={openDrawer}>
          <MenuIcon className={classes.drawerToggleIcon} />
        </IconButton>
        <BasketIconWithBadge className={classes.basketIcon} />
        <DrawerLeft open={drawerOpen} onClose={closeDrawer} />
      </Toolbar>

      <div className={classes.main}>{children}</div>

      <Footer />
    </div>
  );
}

const styles = ({ breakpoints, palette, spacing, typography }) => ({
  body: {
    background: `url(${BodyBackgroundImage}) 50% 0% no-repeat`,
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
  },
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    background: `url(${Bildmarke}) 50% 90% no-repeat`,
    padding: `0 ${spacing.unit * 2}px`,
    [breakpoints.up('md')]: {
      width: breakpoints.width('md') - 4 * spacing.unit,
      margin: '0 auto',
      padding: 0,
    },
  },
  toolbar: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  title: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    ...typography.h6,
    color: 'inherit',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  drawerToggleIcon: {
    color: palette.common.white,
    [breakpoints.up('md')]: {
      fontSize: '2em',
    },
  },
  basketIcon: {
    marginTop: spacing.unit * 2,
    marginRight: spacing.unit * 2,
  },
  main: {
    flex: 1,
  },
});

export default withStyles(styles)(Layout);

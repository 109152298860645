import { IconButton, withStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { NavLink } from 'react-router-dom';

function DrawerLeft({ open, onClose, classes }) {
  return (
    <Drawer open={open} onClose={onClose}>
      <div tabIndex={0} role="button" onClick={onClose} onKeyDown={onClose} className={classes.drawerContainer}>
        <IconButton onClick={onClose} color="inherit">
          <CloseIcon className={classes.closeIcon} />
        </IconButton>

        <List className={classes.drawerMenuList}>
          <NavLink to="/" className={classes.drawerListItemText}>
            <ListItem button>
              <ListItemText primary="Startseite" className={classes.drawerListItemText} />
            </ListItem>
          </NavLink>

          <NavLink to="/so-funktioniert-es" className={classes.drawerListItemText}>
            <ListItem button>
              <ListItemText primary="So funktioniert’s" className={classes.drawerListItemText} />
            </ListItem>
          </NavLink>

          <NavLink to="/faq" className={classes.drawerListItemText}>
            <ListItem button>
              <ListItemText primary="FAQ" className={classes.drawerListItemText} />
            </ListItem>
          </NavLink>

          <NavLink to="/agb" className={classes.drawerListItemText}>
            <ListItem button>
              <ListItemText primary="AGB" className={classes.drawerListItemText} />
            </ListItem>
          </NavLink>

          <NavLink to="/impressum" className={classes.drawerListItemText}>
            <ListItem button>
              <ListItemText primary="Impressum" className={classes.drawerListItemText} />
            </ListItem>
          </NavLink>

          <NavLink to="/kontakt" className={classes.drawerListItemText}>
            <ListItem button>
              <ListItemText primary="Kontakt" className={classes.drawerListItemText} />
            </ListItem>
          </NavLink>
        </List>
      </div>
    </Drawer>
  );
}

const styles = ({ breakpoints, palette }) => ({
  closeIcon: {
    [breakpoints.up('md')]: {
      fontSize: '2em',
    },
  },
  drawerContainer: {
    backgroundColor: '#244862',
    height: '100%',
    width: 400,
    color: palette.common.white,
  },
  drawerListItemText: {
    margin: '0 10%',
    textDecoration: 'none',
    color: 'inherit',
    '& span': {
      fontSize: '2rem',
      fontWeight: 100,
      color: 'inherit',
    },
  },
});

export default withStyles(styles)(DrawerLeft);

import React, { useContext, useState } from 'react';

const storage = window.sessionStorage;
const storageKey = 'bgbp-ankauf-basket';

const emptyBasket = { items: [], total: 0, nextId: 1 };

export const Context = React.createContext({ basket: emptyBasket });

function loadBasket() {
  const data = storage.getItem(storageKey);
  return data ? { ...emptyBasket, ...JSON.parse(data) } : emptyBasket;
}

function saveBasket(basket) {
  storage.setItem(storageKey, JSON.stringify(basket));
}

export function BasketProvider({ children }) {
  const [basket, setStateBasket] = useState(loadBasket());

  const setBasket = updater => {
    setStateBasket(b => {
      const newB = updater(b);
      saveBasket(newB);
      return newB;
    });
  };

  const addBasketItem = item => {
    setBasket(b => ({
      ...b,
      nextId: b.nextId + 1,
      items: [...b.items, { ...item, id: b.nextId.toString() }],
      total: b.total + item.price.amount,
    }));
  };

  const removeBasketItem = id => {
    setBasket(b => {
      const removed = b.items.find(item => item.id === id);
      if (!removed) return b;

      return {
        ...b,
        items: b.items.filter(item => item.id !== id),
        total: b.total - removed.price.amount,
      };
    });
  };

  const setBasketItemCondition = (id, condition) => {
    setBasket(b => {
      const item = b.items.find(item => item.id === id);
      if (!item) return b;

      const newPrice = item.offer.prices.find(p => p.condition === condition);

      const newItems = b.items.map(i =>
        i.id === id
          ? {
              ...i,
              price: newPrice,
            }
          : i
      );

      return {
        ...b,
        items: newItems,
        total: newItems.reduce((s, i) => s + i.price.amount, 0),
      };
    });
  };

  const clearBasket = () => setBasket(() => emptyBasket);

  const context = {
    basket,
    addBasketItem,
    removeBasketItem,
    setBasketItemCondition,
    clearBasket,
  };

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

export function useBasket() {
  return useContext(Context);
}

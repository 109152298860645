import React from 'react';

export default function GlobalError({ error }) {
  return (
    <div>
      <h1>Oh je, oh je&hellip;</h1>
      <p>Da ist wohl etwas schiefgegangen.</p>
      <p>{error.message}</p>
    </div>
  );
}

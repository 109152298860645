import { withStyles } from '@material-ui/core';
import React from 'react';
import Container from './Container';
import { Link } from 'react-router-dom';

function Footer({ classes }) {
  return (
    <footer className={classes.root}>
      <Container>
        &copy; Phunkstelle GbR
        {' – '}
        <Link to="/impressum">Impressum</Link>
        {' – '}
        <Link to="/agb">AGB</Link>
      </Container>
    </footer>
  );
}

const styles = ({ palette, spacing, typography }) => ({
  root: {
    ...typography.body1,
    backgroundColor: 'transparent',
    color: palette.common.white,
    padding: `${spacing.unit}px 0`,
    textAlign: 'right',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
});

export default withStyles(styles)(Footer);

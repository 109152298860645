import { withStyles } from '@material-ui/core';
import React from 'react';
import Delay from './Delay';
import Loading from './Loading';

function GlobalLoader({ classes }) {
  return (
    <Delay delay={1000}>
      <Loading className={classes.root} />
    </Delay>
  );
}

const styles = ({ spacing }) => ({
  root: {
    margin: `0 ${spacing.unit * 3}px`,
  },
});

export default withStyles(styles)(GlobalLoader);
